import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import useEmblaCarousel from 'embla-carousel-react';
import { ButtonComponent } from '@zolteam/axenergie-ui-library';

// Constants
import strings from '../../constants/Strings';

// Hooks
import useIsMobile from '../../hooks/useIsMobile';

// Image
import whiteArrow from '../../public/icons/arrow-big-white.svg';
import whiteArrowLeft from '../../public/icons/arrow-big-white-left.svg';
import GoogleReviews from '../../public/Images/google-reviews.webp';

// Component
import Rating from '../Atoms/Rating';

const HomeReviews = ({ reviews }) => {
  const [reviewsOrdered, setReviewsOrdered] = useState(reviews);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [cRef, cApi] = useEmblaCarousel({
    skipSnaps: true,
    loop: true,
  });
  const [isMobile] = useIsMobile();

  const scrollTo = useCallback((index) => cApi && cApi.scrollTo(index), [
    cApi,
  ]);

  const onSelect = useCallback(() => {
    if (!cApi) return;
    setSelectedIndex(cApi.selectedScrollSnap());
  }, [cApi, setSelectedIndex]);

  useEffect(() => {
    if (!cApi) return;
    onSelect();
    cApi.on('select', onSelect);
  }, [cApi, onSelect]);

  useEffect(() => {
    const id = localStorage.getItem('reviewId');
    let updatedReviews = reviewsOrdered.slice(0);
    updatedReviews = updatedReviews.sort(() => Math.random() - 0.5);
    if (id === updatedReviews[0].id) {
      updatedReviews.push(updatedReviews.splice(0, 1)[0]);
    }
    setReviewsOrdered(updatedReviews);
    localStorage.setItem('reviewId', updatedReviews[0].id);
  }, []);

  return (
    <div className="grey-800-background ph-6 pb-5 pt-0">
      <span className="d-flex justify-center align-center mb-3">
        <p
          className={`
          pt-4 ${isMobile ? 'h3' : 'h2'}
          normal-weight
          text-align-center
          white-text
          mt-0
          mr-4
          ${isMobile ? 'mb-0' : ''}
        `}
        >
          {strings.homeReviewsTitle}
        </p>
        <Image
          layout="fixed"
          src={GoogleReviews}
          alt="google-reviews"
          width={50}
          height={20}
        />
      </span>
      {/* Carousel */}
      <div className={`d-flex align-center ${isMobile ? '' : 'ml-3'} mt-5`} style={{ justifyContent: 'space-between' }}>
        {!isMobile ? (
          <div style={{ flex: '0 0 10%' }}>
            <ButtonComponent theme="dark-grey" onClick={() => cApi.scrollPrev()}>
              <Image
                alt=""
                src={whiteArrowLeft}
                height={40}
                width={33}
              />
            </ButtonComponent>
          </div>
        ) : null}
        <div ref={cRef} style={{ overflow: 'hidden', width: isMobile ? '' : '70%' }}>
          <div className="d-flex">
            {reviewsOrdered.map((review) => (
              <div
                key={review.id}
                className="d-flex f-column align-center justify-center relative white-text text-align-center"
                style={{ flex: '0 0 100%' }}
              >
                <Rating rating={review.rating} />
                <p style={{ color: '#888E9B' }}>
                  {review.content}
                </p>
                <p className={`h6 ${isMobile ? 'mt-0' : 'mt-2'}`}>
                  {review.reviewer}
                </p>
                <p style={{ color: '#888E9B', fontSize: 10, marginTop: -25 }}>
                  {review.adherentName}
                </p>
              </div>
            ))}
          </div>
        </div>
        {!isMobile ? (
          <div className="text-align-right" style={{ flex: '0 0 10%' }}>
            <ButtonComponent theme="dark-grey" onClick={() => cApi.scrollNext()}>
              <Image alt="" src={whiteArrow} height={40} width={33} />
            </ButtonComponent>
          </div>
        ) : null}
      </div>
      <div
        className={`d-flex w-100 justify-center align-center text-align-center ${isMobile ? '' : 'ml-3'} mt-2`}
      >
        <div className="d-flex">
          {reviewsOrdered.map((review, index) => (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <div
              onClick={() => {
                if (index === selectedIndex) return;
                scrollTo(index);
              }}
              role="button"
              tabIndex={0}
              onKeyDown={null}
              key={review.id}
              className="mh-2 pointer"
              style={{
                height: '12px',
                width: '12px',
                backgroundColor: index === selectedIndex ? '#8890A1' : 'rgba(255,255,255,0.1)',
                borderRadius: '50%',
                display: 'inline-block',
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

HomeReviews.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.shape({})),
};

HomeReviews.defaultProps = {
  reviews: null,
};

export default HomeReviews;
