import React, {
  useState, useEffect, useRef,
} from 'react';
// Hooks
import { useMeasure } from 'react-use';

// Components
import Head from 'next/head';
import { SlashContainer } from '@zolteam/axenergie-ui-library';
import Image from 'next/image';
import { useRouter } from 'next/router';
import SearchEngine from '../components/Organisms/SearchEngine';
import Menu from '../components/Molecules/Menu';
import Footer from '../components/Molecules/Footer';
import HomeMainLeftContent from '../components/Molecules/HomeMainLeftContent';
import EnergiesBanner from '../components/Molecules/EnergiesBanner';
import HomeExpertsBlock from '../components/Molecules/HomeExpertsBlock';
import ReassuranceBanner from '../components/Molecules/ReassuranceBanner';
import HomePartnerBlock from '../components/Molecules/HomePartnerBlock';
import NewsBlock from '../components/Molecules/NewsBlock';
import HomeReviews from '../components/Molecules/HomeReviews';

// Services and hooks
import menuAndFooterService from '../services/contentful/menuAndFooterService';
import homePageService from '../services/contentful/homePageService';
import useIsMobile from '../hooks/useIsMobile';
import AgencyService from '../services/api/AgencyService';

// Constants
import colors from '../constants/Colors';
import strings from '../constants/Strings';
import titleMetaDescription from '../constants/TitleMetaDescription';

// Utils
import utils from '../utils/formatCMS';
import searchEngineUtils from '../utils/searchEngineUtils';

// Images
import searchIcon from '../public/icons/search.svg';

// Styles
import styles from '../styles/index.module.scss';

const Home = (props) => {
  // Styles
  const redSearchEngine = {
    bottom: 0,
    right: 0,
    backgroundColor: colors.primaryRed,
    position: 'absolute',
    paddingTop: '3rem',
    paddingBottom: '3rem',
  };
  const whiteSearchEngine = {
    top: 0,
    left: 0,
    backgroundColor: colors.white,
    position: 'fixed',
    zIndex: 2,
    width: '100vw',
  };

  const router = useRouter();

  // Local states
  const [searchEngineStyle, setSearchEngineStyle] = useState(redSearchEngine);
  const [searchEngineTheme, setSearchEngineTheme] = useState('full');

  // Refs
  const searchEngineRef = useRef(null);
  const [expertsContentRef, { height: expertsContentHeight }] = useMeasure();
  const [mainPanelRef, { height: mainPanelHeight }] = useMeasure();

  // Hooks
  const [isMobile] = useIsMobile();

  useEffect(() => {
    window.onscroll = () => {
      let bottom = 10;
      if (searchEngineRef.current) {
        bottom = searchEngineRef.current.getBoundingClientRect().bottom;
      }
      if (bottom <= 0) {
        setSearchEngineStyle(whiteSearchEngine);
        setSearchEngineTheme('light');
      }

      if (window.scrollY - 220 < window.innerHeight) {
        setSearchEngineStyle(redSearchEngine);
        setSearchEngineTheme('full');
      }
    };
  }, [redSearchEngine, whiteSearchEngine]);

  const renderMobile = () => (
    <div>
      {/* Search sticky */}
      {
        searchEngineTheme === 'light'
          ? (
            <button
              type="button"
              onClick={() => searchEngineRef.current?.scrollIntoView()}
              className="fixed full-screen-width primary-red-background ph-5"
              style={{ bottom: 0, left: 0, zIndex: 2 }}
            >
              <div className="d-flex f-row justify-between align-center mv-4">
                <h5 className="white-text medium-weight m-0">{strings.findPro}</h5>
                <Image alt="search" src={searchIcon} width={24} />
              </div>
            </button>
          )
          : null
      }

      {/* Main block and menu */}
      <div
        className="background-cover bgIndex"
        style={
          {
            backgroundImage:
                `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
                url(${props.imagePrincipale.fields.file.url}?fm=webp&w=820&h=430)`,
          }
        }
      >
        <div>
          <Menu isMobile={isMobile} items={utils.formatCMSDatas('menu', props)} fontColor="grey-800-text" />
        </div>
        <div className="d-flex f-column align-start white-text p-5">
          <h1>{props.titreDeLaPage}</h1>
          <p>{props.sousTitreDeLaPage}</p>
        </div>

      </div>
      {/* Search engine */}
      <div
        ref={searchEngineRef}
        className="home-mobile-search-engine-container"
      >
        <SlashContainer width="100vw" backgroundColor={colors.primaryRed} position="top" offset={20}>
          <div className="d-flex align-center justify-center mv-3 fgIndex">
            <SearchEngine
              theme="full"
              handleSubmit={(data) => searchEngineUtils.goToSearchPage(router, data)}
            />
          </div>
        </SlashContainer>
      </div>
      {/* Energies block */}
      <div className="d-flex full-screen-width justify-center">
        <EnergiesBanner energies={utils.formatCMSEnergies(props)} isMobile={isMobile} />
      </div>
      {/* Experts block */}
      <div className="d-flex full-screen-width justify-center">
        <HomeExpertsBlock
          imgLink={props.lienImageBlocExpert}
          image={props.imageBlocExperts.fields.file.url}
          title={props.titreBlocExperts}
          body={props.texteBlocExperts}
          logos={utils.formatCMSLogoExpert(props)}
          buttonTitle={props.libellBoutonBlocExpert}
          buttonLink={props.lienBoutonBlocExpert}
          isMobile={isMobile}
        />
      </div>
      {/* Partners block */}
      <HomePartnerBlock
        mainImage={`${props.blocPartenaire.fields.imageBlocPartenaires.fields.file.url}`}
        title={props.blocPartenaire.fields.titreBlocPartenaires}
        body={props.blocPartenaire.fields.texteBlocPartenaires}
        buttonLink={props.blocPartenaire.fields.lienBoutonBlocPartenaire}
        buttonTitle={props.blocPartenaire.fields.libellBoutonBlocPartenaire}
        text={props.blocPartenaire.fields.textInstallationBlocPartenaires}
        partners={utils.formatCMSPartners(props)}
        isMobile={isMobile}
      />
      {/* News Bloc */}
      {props.news?.length ? (
        <NewsBlock
          goToList={() => router.push('/conseils')}
          news={props.news}
        />
      ) : null}
      {props.reviews?.length ? (
        <HomeReviews
          reviews={props.reviews}
        />
      ) : null}
      {/* Reassurance service */}
      <ReassuranceBanner items={utils.formatCMSReassurance(props)} />
      {/* Footer */}
      <Footer
        networkBtn={props.footerRseauLienBouton}
        clientItems={utils.formatCMSDatas('footerClient', props)}
        networkItems={utils.formatCMSDatas('footerRseau', props)}
        facebook={props.footerFacebook}
        instagram={props.footerInstagram}
        twitter={props.footerTwitter}
        mentions={props.mentions}
        legalItems={utils.formatCMSDatas('sousFooter', props)}
        isMobile
      />
    </div>
  );

  const renderDesktop = () => (
    <div>
      {/* Main block */}
      <div className="full-width relative" style={{ height: mainPanelHeight }}>
        {/* Menu */}
        <div className="absolute absolute-right-top-corner index-2">
          <Menu items={utils.formatCMSDatas('menu', props)} />
        </div>
        {/* Left black slash */}
        <div ref={mainPanelRef} className="absolute full-width fgIndex absolute-left-top-corner home-black-left-panel">
          <SlashContainer width="25%" backgroundColor={colors.grey800} offset={40}>
            <HomeMainLeftContent
              jobs={utils.formatCMSJobs(props)}
              title={props.titreDeLaPage}
              subtitle={props.sousTitreDeLaPage}
            />
          </SlashContainer>
        </div>
        {/* Main image */}
        <div
          className="d-flex f-column justify-center align-end home-desktop-main-image-container"
        >
          <div
            className="home-desktop-main-image"
            style={
              {
                backgroundImage:
                    `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
                url(${props.imagePrincipale.fields.file.url}?fm=webp&w=1440&h=640)`,
              }
            }
          />
        </div>
        {/* Search Engine */}
        <div
          ref={searchEngineRef}
          className={`
          d-flex f-column justify-center align-end ph-6
            ${searchEngineTheme === 'full'
            ? styles.redSearchEngine
            : ''}
            `}
          style={searchEngineStyle}
        >
          <div className="full-width d-flex justify-center align-center">
            <SearchEngine
              theme={searchEngineTheme}
              handleSubmit={(data) => searchEngineUtils.goToSearchPage(router, data)}
            />
          </div>
        </div>
      </div>
      {/* Second block energies and experts */}
      <div className="full-width relative" style={{ height: expertsContentHeight }}>
        {/* Background grey slash */}
        <div className="absolute absolute-left-top-corner full-width">
          <SlashContainer width="20%" backgroundColor={colors.grey100} offset={40}>
            <div style={{ height: expertsContentHeight }} />
          </SlashContainer>
        </div>
        <div ref={expertsContentRef} className="absolute absolute-left-top-corner fgIndex full-width">
          <div className="d-flex justify-center mt-6">
            <EnergiesBanner energies={utils.formatCMSEnergies(props)} />
          </div>
          <HomeExpertsBlock
            imgLink={props.lienImageBlocExpert}
            image={props.imageBlocExperts.fields.file.url}
            title={props.titreBlocExperts}
            body={props.texteBlocExperts}
            logos={utils.formatCMSLogoExpert(props)}
            buttonTitle={props.libellBoutonBlocExpert}
            buttonLink={props.lienBoutonBlocExpert}
          />
        </div>
      </div>
      {/* Partners block */}
      <HomePartnerBlock
        mainImage={`${props.blocPartenaire.fields.imageBlocPartenaires.fields.file.url}?fm=webp`}
        title={props.blocPartenaire.fields.titreBlocPartenaires}
        body={props.blocPartenaire.fields.texteBlocPartenaires}
        buttonLink={props.blocPartenaire.fields.lienBoutonBlocPartenaire}
        buttonTitle={props.blocPartenaire.fields.libellBoutonBlocPartenaire}
        text={props.blocPartenaire.fields.textInstallationBlocPartenaires}
        partners={utils.formatCMSPartners(props)}
      />
      {/* News Bloc */}
      {props.news?.length ? (
        <NewsBlock
          goToList={() => router.push('/conseils')}
          news={props.news}
        />
      ) : null}
      {props.reviews?.length ? (
        <HomeReviews
          reviews={props.reviews}
        />
      ) : null}
      {/* Reassurance banner */}
      <ReassuranceBanner items={utils.formatCMSReassurance(props)} />
      {/* Footer */}
      <Footer
        networkBtn={props.footerRseauLienBouton}
        clientItems={utils.formatCMSDatas('footerClient', props)}
        networkItems={utils.formatCMSDatas('footerRseau', props)}
        facebook={props.footerFacebook}
        instagram={props.footerInstagram}
        twitter={props.footerTwitter}
        mentions={props.mentions}
        legalItems={utils.formatCMSDatas('sousFooter', props)}
      />
    </div>
  );

  return (
    <div>
      <Head>
        <title>{titleMetaDescription.homePage.title}</title>

        <meta name="description" content={titleMetaDescription.homePage.description} />
        <meta property="og:title" content={titleMetaDescription.homePage.title} />
        <meta property="og:description" content={titleMetaDescription.homePage.description} />
        <meta property="og:image" content={`https://${process.env.NEXT_PUBLIC_DOMAIN}/Images/og-image.png`} />
        <meta
          property="og:image:secure_url"
          content={`https://${process.env.NEXT_PUBLIC_DOMAIN}/Images/og-image.png`}
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {
        isMobile
          ? renderMobile()
          : renderDesktop()
      }
    </div>
  );
};

export default Home;

export async function getServerSideProps() {
  let menuAndFooter;
  let homePage;
  let news;
  let reviews;

  try {
    menuAndFooter = await menuAndFooterService.menuAndFooter();
  } catch (err) {
    menuAndFooter = {};
    console.error(err);
  }

  try {
    homePage = await homePageService.homePage();
  } catch (err) {
    homePage = {};
    console.error(err);
  }

  try {
    news = await AgencyService.getAgencyPageContents({});
  } catch (err) {
    news = {};
    console.error(err);
  }

  try {
    reviews = await AgencyService.getAgencyReviews({ quantity: 5 });
  } catch (err) {
    reviews = [];
    console.error(err);
  }

  return {
    props: {
      ...menuAndFooter?.fields,
      ...homePage?.fields,
      reviews: reviews?.data?.agencyReviews || [],
      news: news?.data?.agencyPageContents?.splice(0, 8) || [],
    },
  };
}
