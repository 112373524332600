/* eslint-disable max-len */
const titleMetaDescription = {
  homePage: {
    title: 'Le réseau n°1 de plombiers chauffagistes en France | Axenergie',
    description: 'Vous recherchez un plombier chauffagiste frigoriste près de chez vous ? Découvrez Axenergie, le 1er réseau de plus de 120 chauffagistes indépendants en France !',
  },
  adviceListPage: {
    title: 'Conseils et astuces | Axenergie',
    description: 'Vous trouverez dans cette section des informations utiles concernant votre système de chauffage.',
  },
  searchPage: {
    title: 'Trouver mon chauffagiste | Axenergie',
    description: 'Trouvez votre chauffagiste',
  },
  jobOfferPage: {
    title: 'Offres d\'emploi | Axenergie',
    description: 'Vous trouverez dans cette section les offres d\'emploi des agences et du réseau Axenergie',
  },
  sitemapPage: {
    title: 'Plan de site | Axenergie',
    description: 'Plan du site Axenergie',
  },
};

export default titleMetaDescription;
