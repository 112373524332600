import React from 'react';
import PropTypes from 'prop-types';

// Components
import Image from 'next/image';

// Images
import logoWhite from '../../public/Images/logo-white.webp';

const HomeMainLeftContent = ({ jobs, title, subtitle }) => (
  <div className="d-flex f-column justify-between align-start white-text content pt-4 pl-3" style={{ width: '100%' }}>
    <div className="ml-4 mt-4" style={{ width: 210, height: 210 }}>
      <Image
        alt="logo"
        src={logoWhite}
        layout="fixed"
        width={210}
        height={210}
      />
    </div>
    <div className="d-flex justify-center f-column align-start pl-5">
      <h1 className="medium-weight">{title}</h1>
      <p>{subtitle}</p>
    </div>
    <div className="mb-5 pl-5">
      {
        jobs.map((job) => (
          <div
            key={`${job.label}-${job.picto}`}
            className="d-flex d-row align-center mv-5"
          >
            <Image alt="picto" src={`/icons/${job.picto}.svg`} width={30} height={30} layout="fixed" />
            <span className="grey-400-text ml-3">{job.label}</span>
          </div>
        ))
      }
    </div>
  </div>
);

HomeMainLeftContent.propTypes = {
  jobs: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, picto: PropTypes.string })).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default HomeMainLeftContent;
