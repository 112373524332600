import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { ButtonComponent, SlashContainer } from '@zolteam/axenergie-ui-library';
import Image from 'next/image';
import Link from 'next/link';

// Images
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useMeasure } from 'react-use';
import arrow from '../../public/icons/arrow-big.svg';

// Utils
import colors from '../../constants/Colors';
import whiteArrow from '../../public/icons/arrow-big-white.svg';

// Hooks

const HomePartnerBlock = (
  {
    mainImage,
    isMobile,
    title,
    body,
    buttonTitle,
    buttonLink,
    text,
    partners,
  },
) => {
  const [partnersContentRef, { height: partnersContentHeight }] = useMeasure();
  const [isButtonHover, setIsButtonHover] = useState(false);

  const renderMobile = () => (
    <div className="full-screen-width">
      <div
        className="background-cover p-5"
        style={{
          background: `linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
                url(${mainImage}&w=${isMobile ? 100 : 140}&h=${isMobile ? 100 : 140})`,
        }}
      >
        <h2 className="medium-weight">{title}</h2>
        <p className="grey-600-text mb-6">{body}</p>
        <div
          style={{ display: 'inline-block' }}
          onMouseLeave={() => setIsButtonHover(false)}
          onMouseEnter={() => setIsButtonHover(true)}
        >
          <Link href={buttonLink}>
            <a>
              <ButtonComponent
                onClick={() => {}}
                theme="outline"
              >
                <div
                  className="d-flex f-row justify-start align-center large-button"
                >
                  <div className="d-flex f-column align-start mr-4">
                    <span className="bold">{buttonTitle}</span>
                  </div>
                  <div className="mr-2">
                    {
                              isButtonHover
                                ? <Image alt="arrow" src={whiteArrow} width={20} />
                                : <Image alt="arrow" src={arrow} width={20} />
                          }
                  </div>
                </div>
              </ButtonComponent>
            </a>
          </Link>
        </div>

      </div>
      <div className="fgIndex home-partners-mobile-white-container">
        <SlashContainer width="100vw" backgroundColor={colors.white} position="top" offset={20}>
          <div className="d-flex f-row f-wrap justify-center align-center">
            {
                partners.map((partner, index) => (
                  <Link
                    href={partner.link}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`partner-${index}`}
                    className="mv-5"
                  >
                    <a className="m-3">
                      <Image
                        alt="partners"
                        loader={() => `https:${partner.imageUrl}`}
                        src={`https:${partner.imageUrl}`}
                        width={140}
                        height={100}
                        objectFit="contain"
                      />
                    </a>
                  </Link>
                ))
            }
            <div className="p-5">
              {documentToReactComponents(text)}
            </div>

          </div>
        </SlashContainer>
      </div>

    </div>
  );
  const renderDesktop = () => (
    <div className="full-width relative d-flex">
      <div
        ref={partnersContentRef}
        className="background-cover home-partners-desktop-left-container d-flex"
        style={{
          background: `linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
                url(${mainImage}&w=${isMobile ? 100 : 1500}&h=${isMobile ? 100 : 1500})`,
        }}
      >
        <div className="d-flex f-column justify-center align-start p-6 home-partners-desktop-left-content">
          <div className="mb-6 mt-4 ph-6">
            <h2 className="medium-weight">{title}</h2>
            <p className="grey-600-text">{body}</p>
            <div
              style={{ display: 'inline-block' }}
              onMouseLeave={() => setIsButtonHover(false)}
              onMouseEnter={() => setIsButtonHover(true)}
            >
              <Link href={buttonLink}>
                <a>
                  <ButtonComponent
                    onClick={() => {}}
                    theme="outline"
                  >
                    <div
                      className="d-flex f-row justify-start align-center large-button"
                    >
                      <div className="d-flex f-column align-start mr-4">
                        <span className="bold">{buttonTitle}</span>
                      </div>
                      <div className="mr-2">
                        {
                      isButtonHover
                        ? <Image alt="arrow" src={whiteArrow} width={20} />
                        : <Image alt="arrow" src={arrow} width={20} />
                    }
                      </div>
                    </div>
                  </ButtonComponent>
                </a>
              </Link>
            </div>
          </div>
          <div className="ph-6">
            {documentToReactComponents(text)}
          </div>
        </div>
      </div>
      <div className="fgIndex home-partners-desktop-right-container">
        <SlashContainer width="100%" offset={30} backgroundColor={colors.white}>
          <div
            className="d-flex f-wrap f-column justify-center align-center home-partners-desktop-right-content"
            style={{ minHeight: partnersContentHeight }}
          >
            {
                partners.map((partner, index) => (
                  <Link
                    href={partner.link}
                      // eslint-disable-next-line react/no-array-index-key
                    key={`partner-${index}`}
                  >
                    <a>
                      <Image
                        alt="partners"
                        loader={() => `https:${partner.imageUrl}`}
                        src={`https:${partner.imageUrl}`}
                        width={140}
                        height={100}
                        objectFit="contain"
                      />
                    </a>
                  </Link>
                ))
            }
          </div>
        </SlashContainer>
      </div>
    </div>
  );
  return isMobile ? renderMobile() : renderDesktop();
};

HomePartnerBlock.propTypes = {
  isMobile: PropTypes.bool,
  mainImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  text: PropTypes.shape({}).isRequired,
  partners: PropTypes.arrayOf(PropTypes.shape(
    { label: PropTypes.string, imageUrl: PropTypes.string, link: PropTypes.string },
  )).isRequired,
};

HomePartnerBlock.defaultProps = {
  isMobile: false,
};

export default HomePartnerBlock;
