import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

// Components
import Image from 'next/image';
import { ButtonComponent, SlashContainer } from '@zolteam/axenergie-ui-library';

// Images
import arrow from '../../public/icons/arrow-big.svg';
import whiteArrow from '../../public/icons/arrow-big-white.svg';

// Utils
import colors from '../../constants/Colors';

const HomeExpertsBlock = ({
  image, title, body, logos, buttonTitle, buttonLink, isMobile, imgLink,
}) => {
  const [isButtonHover, setIsButtonHover] = useState(false);

  const renderDesktop = () => (
    <div className="d-flex f-row pv-3">
      <div className="relative mt-3 home-experts-main-image-container ml-4 mr-5">
        {
          imgLink
            ? (
              <Link href={imgLink}>
                <a target="_blank" rel="noreferrer">
                  <Image
                    alt="experts"
                    src={`https:${image}?fm=webp&w=${
                      isMobile ? 820 : 920}&h=${isMobile ? 640 : 470}`}
                    layout="fill"
                    objectFit="contain"
                  />
                </a>
              </Link>
            )
            : (
              <Image
                alt="experts"
                src={`https:${image}?fm=webp&w=${
                  isMobile ? 820 : 920}&h=${isMobile ? 640 : 470}`}
                layout="fill"
                objectFit="contain"
              />
            )
        }
      </div>
      <div className="d-flex f-column align-start justify-center pr-6 home-experts-body-text mt-6">
        <h2 className="medium-weight">{title}</h2>
        <p className="grey-600-text pr-6">{body}</p>
        <div className="d-flex f-row f-wrap align-center justify-center mb-4">
          {
              logos.map((logo) => (
                <div className="m-2" key={logo.url}>
                  <Image
                    alt="experts"
                    loader={() => `https:${logo.url}?fm=webp&w=70&h=70`}
                    src={`https:${logo.url}?fm=webp&w=70&h=70`}
                    width={70}
                    height={70}
                    objectFit="contain"
                  />
                </div>
              ))
          }
        </div>
        <div
          onMouseLeave={() => setIsButtonHover(false)}
          onMouseEnter={() => setIsButtonHover(true)}
        >
          <Link href={buttonLink}>
            <a>
              <ButtonComponent onClick={() => {}} theme="outline">
                <div
                  className="d-flex f-row justify-start align-center large-button"
                >
                  <div className="d-flex f-column align-start mr-4">
                    <span className="bold">{buttonTitle}</span>
                  </div>
                  <div className="mr-2">
                    {
                isButtonHover
                  ? <Image alt="arrow" src={whiteArrow} width={20} />
                  : <Image alt="arrow" src={arrow} width={20} />
              }
                  </div>
                </div>
              </ButtonComponent>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );

  const renderMobile = () => (
    <div>
      <div className="relative mt-5 home-experts-main-image-container-mobile">
        {
          imgLink
            ? (
              <Link href={imgLink}>
                <a target="_blank" rel="noreferrer">
                  <Image
                    alt="experts"
                    src={`https:${image}?fm=webp&w=${
                      isMobile ? 820 : 1440}&h=${isMobile ? 430 : 640}`}
                    layout="fill"
                    objectFit="contain"
                  />
                </a>
              </Link>
            )
            : (
              <Image
                alt="experts"
                src={`https:${image}?fm=webp&w=${
                  isMobile ? 820 : 1440}&h=${isMobile ? 430 : 640}`}
                layout="fill"
                objectFit="contain"
              />
            )
        }
      </div>
      <div className="home-experts-text-container">
        <SlashContainer width="100vw" position="top" offset={20} backgroundColor={colors.white}>
          <div className="d-flex f-column align-start justify-center ph-5 pv-4 home-experts-body-text-mobile">
            <h2 className="medium-weight">{title}</h2>
            <p className="grey-600-text">{body}</p>
            <div className="d-flex f-row f-wrap align-center justify-center mb-4">
              {
                  logos.map((logo) => (
                    <div className="m-2" key={logo.url}>
                      <Image
                        alt="experts"
                        loader={() => `https:${logo.url}?fm=webp&w=70&h=70`}
                        src={`https:${logo.url}?fm=webp&w=70&h=70`}
                        width={70}
                        height={70}
                        objectFit="contain"
                      />
                    </div>
                  ))
              }
            </div>
            <div
              onMouseLeave={() => setIsButtonHover(false)}
              onMouseEnter={() => setIsButtonHover(true)}
            >
              <Link href={buttonLink}>
                <a>
                  <ButtonComponent onClick={() => {}} theme="outline">
                    <div
                      className="d-flex f-row justify-start align-center large-button"
                    >
                      <div className="d-flex f-column align-start mr-4">
                        <span className="bold">{buttonTitle}</span>
                      </div>
                      <div className="mr-2">
                        {
                    isButtonHover
                      ? <Image alt="arrow" src={whiteArrow} width={20} />
                      : <Image alt="arrow" src={arrow} width={20} />
                  }
                      </div>
                    </div>
                  </ButtonComponent>
                </a>
              </Link>
            </div>
          </div>
        </SlashContainer>
      </div>
    </div>
  );
  return isMobile ? renderMobile() : renderDesktop();
};

HomeExpertsBlock.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  logos: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string })),
  buttonTitle: PropTypes.string.isRequired,
  imgLink: PropTypes.string,
  buttonLink: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
};

HomeExpertsBlock.defaultProps = {
  logos: [],
  isMobile: false,
  imgLink: null,
};

export default HomeExpertsBlock;
