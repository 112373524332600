import React from 'react';
import PropTypes from 'prop-types';

// Components
import { SlashContainer } from '@zolteam/axenergie-ui-library';
import Image from 'next/image';

// Utils
import colors from '../../constants/Colors';

const EnergiesBanner = ({ energies, isMobile }) => {
  const getDesktopWidth = (index, arrayLength) => {
    if (index === 0) {
      return 'calc(100% - 20px)';
    } if (index === arrayLength - 1) {
      return 'calc(100% + 20px)';
    }
    return '100%';
  };
  const renderDesktop = () => (
    <>
      {
                energies.map((energy, index, array) => (
                  <div
                    style={{ zIndex: 4 - index, width: '20%' }}
                    key={`${energy.label}-${energy.picto}`}
                  >
                    <SlashContainer
                      width={getDesktopWidth(index, array.length)}
                      backgroundColor={index % 2 === 0 ? colors.white : colors.grey100}
                      offset={index === array.length - 1 ? 0 : 10}
                    >
                      <div
                        className={
                                `${index === 0 ? '' : 'ml-5'} pv-5 d-flex f-column
                          align-center justify-start energies-banner-block`
                              }
                      >
                        <div className="relative mb-3" style={{ width: 30, height: 30 }}>
                          <Image
                            src={`/icons/${energy.picto}.svg`}
                            alt={energy.label}
                            width={30}
                            height={30}
                            layout="fixed"
                          />
                        </div>
                        <h6 className="grey-800-text m-0 energies-banner-title">{energy.label}</h6>
                        <span className="small-text grey-600-text">{energy.details}</span>
                      </div>
                    </SlashContainer>
                  </div>
                ))
            }
    </>
  );

  const renderMobile = () => (
    <div className="full-width">
      {
                energies.map((energy, index) => (
                  <div className="full-width d-flex f-column" key={`${energy.label}-${energy.picto}`}>
                    <div
                      className="pv-3 d-flex f-column align-center justify-center"
                      style={{ backgroundColor: index % 2 === 0 ? colors.white : colors.grey100 }}
                    >
                      <Image src={`/icons/${energy.picto}.svg`} alt={energy.label} width={30} height={30} />
                      <h6 className="grey-800-text m-0 energies-banner-title">{energy.label}</h6>
                      <span className="small-text grey-600-text">{energy.details}</span>
                    </div>
                  </div>
                ))
            }
    </div>
  );

  return isMobile ? renderMobile() : renderDesktop();
};

EnergiesBanner.propTypes = {
  energies: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    details: PropTypes.string,
    link: PropTypes.string,
    picto: PropTypes.string,
  })).isRequired,
  isMobile: PropTypes.bool,
};

EnergiesBanner.defaultProps = {
  isMobile: false,
};

export default EnergiesBanner;
